import { Component, Input, OnInit } from '@angular/core';
import { darkenHex, isColorDarkEnoughForWhiteText } from '../../utils';

@Component({
  selector: 'LabelTag',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.scss']
})
export class LabelComponent implements OnInit {

  @Input() name: string;
  @Input() color: string;

  public canUseWhiteText: boolean = true;
  public borderColor: string = "transparent";

  constructor() { }

  ngOnInit() {
    this.canUseWhiteText = isColorDarkEnoughForWhiteText(this.color);
    this.borderColor = darkenHex(this.color, 20);
  }


}
