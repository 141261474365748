import {Component, OnInit, OnDestroy, Input, OnChanges} from '@angular/core';
import * as svg from "../../../shared/svg.inject";

@Component({
  selector: "ButtonField",
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonField implements OnInit, OnChanges  {

  @Input() action: any;
  @Input() type: any;
  @Input() class: any;
  @Input() text: any;
  @Input() active: boolean = false;
  @Input() disabled: boolean = false;
  @Input() icononly: boolean = false;
  @Input() buttonType: string = 'submit';
  @Input() noMargins: boolean = false;
  @Input() ariaLabel: string = '';

  public classes: any;

  constructor() {}

  ngOnInit() {
    this.classes = {
      'alert': this.type === 'alert',
      'success': this.type === 'success',
      'neutral': this.type === 'neutral',
      'starred': this.type === 'starred',
      'outlined': this.type === 'outlined' || this.type === 'borderless',
      'borderless': this.type === 'borderless',
      'transparent': this.type === 'transparent',
      'submit': this.type === 'submit',
      'disabled': this.disabled,
      'active': this.active,
      'no-margins': this.noMargins,
      'hide-text-for-mobile': this.class === 'hide-text-for-mobile',
    }
  }

  ngOnChanges(changes) {
    if(changes && changes.active) {
      this.classes = {
        ...this.classes,
        active: changes.active.currentValue,
      }
    }
  }
}
