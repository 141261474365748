import { RouterStateSerializer } from '@ngrx/router-store';
import { RouterStateSnapshot, Params } from '@angular/router';

/**
 * The RouterStateSerializer takes the current RouterStateSnapshot
 * and returns any pertinent information needed. The snapshot contains
 * all information about the state of the router at the given point in time.
 * The entire snapshot is complex and not always needed. In this case, you only
 * need the URL and query parameters from the snapshot in the store. Other items could be
 * returned such as route parameters and static route data.
 */

export interface RouterStateUrl {
  url: string;
  queryParams: Params;
}

export class CustomRouterStateSerializer implements RouterStateSerializer<RouterStateUrl> {
  serialize(routerState: RouterStateSnapshot): RouterStateUrl {
    const { url } = routerState;
    const queryParams = routerState.root.queryParams;

    return { url, queryParams };
  }
}

/**
 * Determines if a given hex color is dark enough for white text to be legible.
 * 
 * This function calculates the relative luminance of the provided color and
 * checks if the contrast ratio with white text meets the WCAG AA standard 
 * for normal text (contrast ratio of at least 4.5:1).
 * 
 * @param hexColor - The hex color code (e.g., '#FFFFFF' or 'FFFFFF').
 * @returns A boolean indicating whether the color is dark enough for white text.
 */
export function isColorDarkEnoughForWhiteText(hexColor: string): boolean {
  const luminance = getRelativeLuminance(hexColor);
  const contrastRatio = getContrastRatio(luminance, 1); // 1 is the luminance of white
  
  return contrastRatio >= 1.5; // Set threshold here
}

/**
 * Calculates the relative luminance of a hex color.
 * 
 * @param hexColor - The hex color code (e.g., '#FFFFFF' or 'FFFFFF').
 * @returns The relative luminance value between 0 (darkest) and 1 (lightest).
 */
function getRelativeLuminance(hexColor: string): number {
  // Remove the hash symbol if present
  const cleanHex = hexColor.replace('#', '');
  
  // Ensure the hex code is 6 characters
  const fullHex = cleanHex.length === 3
      ? cleanHex.split('').map(char => char + char).join('')
      : cleanHex.length === 6
          ? cleanHex
          : '000000'; // Default to black if invalid
  
  // Convert hex to RGB
  const r = parseInt(fullHex.substr(0, 2), 16) / 255;
  const g = parseInt(fullHex.substr(2, 2), 16) / 255;
  const b = parseInt(fullHex.substr(4, 2), 16) / 255;
  
  // Apply the sRGB transformation
  const [R, G, B] = [r, g, b].map(channel => {
      return channel <= 0.03928
          ? channel / 12.92
          : Math.pow((channel + 0.055) / 1.055, 2.4);
  });
  
  // Calculate the relative luminance
  return 0.2126 * R + 0.7152 * G + 0.0722 * B;
}

/**
* Calculates the contrast ratio between two luminance values.
* 
* @param luminance1 - The relative luminance of the first color.
* @param luminance2 - The relative luminance of the second color.
* @returns The contrast ratio as a number.
*/
function getContrastRatio(luminance1: number, luminance2: number): number {
  const L1 = Math.max(luminance1, luminance2);
  const L2 = Math.min(luminance1, luminance2);
  return (L1 + 0.05) / (L2 + 0.05);
}

// Calculates a darker shade of the input color as a hex
export function darkenHex(hex: string, percent: number = 10): string {
  // Remove "#" if present
  hex = hex.replace(/^#/, '');
  // Parse the red, green, and blue values
  const num = parseInt(hex, 16);
  let r = (num >> 16) & 0xff;
  let g = (num >> 8) & 0xff;
  let b = num & 0xff;

  // Decrease each color channel by the given percentage
  r = Math.max(0, r - Math.floor((r * percent) / 100));
  g = Math.max(0, g - Math.floor((g * percent) / 100));
  b = Math.max(0, b - Math.floor((b * percent) / 100));

  // Convert back to hex and return
  return '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
}