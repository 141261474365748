import { Action } from '@ngrx/store';
import {Task} from "protractor/built/taskScheduler";

export const SET_SCROLL_POSITION = '[Media] Set scroll position';
export const RESET_MEDIA = '[Media] Reset media';
export const FIND_MEDIA = '[Media] Find media';
export const FIND_MEDIA_SUCCESS = '[Media] Find media Success';
export const FIND_MEDIA_FAILURE = '[Media] Find media Failure';
export const FIND_VAULT = '[Media] Find vault';
export const FIND_VAULT_SUCCESS = '[Media] Find vault Success';
export const FIND_VAULT_FAILURE = '[Media] Find vault Failure';
export const GET_EXPORT_MEDIA = '[Media] Get export media';
export const GET_EXPORT_MEDIA_SUCCESS = '[Media] Get export media Success';
export const GET_EXPORT_MEDIA_FAILURE = '[Media] Get export media Failure';
export const GET_MEDIA = '[Media] Get media';
export const GET_MEDIA_SUCCESS = '[Media] Get media Success';
export const GET_MEDIA_FAILURE = '[Media] Get media Failure';
export const GET_ANALYSIS = '[Media] Get analysis';
export const GET_ANALYSIS_SUCCESS = '[Media] Get analysis Success';
export const GET_ANALYSIS_FAILURE = '[Media] Get analysis Failure';
export const GET_VAULT = '[Media] Get vault';
export const GET_VAULT_SUCCESS = '[Media] Get vault Success';
export const GET_VAULT_FAILURE = '[Media] Get vault Failure';
export const GET_DAYS = '[Media] Get days';
export const GET_DAYS_SUCCESS = '[Media] Get days Success';
export const GET_DAYS_FAILURE = '[Media] Get days Failure';
export const GET_HOURS = '[Media] Get hours';
export const GET_HOURS_SUCCESS = '[Media] Get hours Success';
export const GET_HOURS_FAILURE = '[Media] Get hours Failure';
export const GET_HOURS_FOR_FILTER = '[Media] Get hours for filter';
export const GET_HOURS_FOR_FILTER_SUCCESS = '[Media] Get hours for filter Success';
export const GET_HOURS_FOR_FILTER_FAILURE = '[Media] Get hours for filter Failure';
export const STAR_SEQUENCE = '[Media] Star sequence';
export const STAR_SEQUENCE_SUCCESS = '[Media] Star sequence Success';
export const STAR_SEQUENCE_FAILURE = '[Media] Star sequence Failure';
export const DELETE_SEQUENCE = '[Media] Delete sequence';
export const DELETE_SEQUENCE_SUCCESS = '[Media] Delete sequence Success';
export const DELETE_SEQUENCE_FAILURE = '[Media] Delete sequence Failure';
export const ADD_LABEL = '[Media] Add label';
export const ADD_LABEL_SUCCESS = '[Media] Add label Success';
export const ADD_LABEL_FAILURE = '[Media] Add label Failure';
export const UPDATE_DESCRIPTION = '[Media] Update description';
export const UPDATE_DESCRIPTION_SUCCESS = '[Media] Update description Success';
export const UPDATE_DESCRIPTION_FAILURE = '[Media] Update description Failure';
export const REMOVE_LABEL = '[Media] Remove label';
export const REMOVE_LABEL_SUCCESS = '[Media] Remove label Success';
export const REMOVE_LABEL_FAILURE = '[Media] Remove label Failure';
export const GET_FILTER = '[Media] Get filter';
export const SET_FILTER = '[Media] Set filter';
export const ADD_TO_VAULT = '[Media] Add to vault';
export const ADD_TO_VAULT_SUCCESS = '[Media] Add to vault Success';
export const ADD_TO_VAULT_FAILURE = '[Media] Add to vault Failure';
export const REMOVE_FROM_VAULT = '[Media] Remove from vault';
export const REMOVE_FROM_VAULT_SUCCESS = '[Media] Remove from vault Success';
export const REMOVE_FROM_VAULT_FAILURE = '[Media] Remove from vault Failure';
export const TASK_CREATED_SUCCESS = '[Media] Created Task for media';
export const TASK_DELETED_SUCCESS = '[Media] Deleted Task for media';
export const SHARE_MEDIA = '[Media] Share media';
export const SHARE_MEDIA_SUCCESS = '[Media] Share media Success';
export const SHARE_MEDIA_FAILURE = '[Media]Share media Failure';
export const RESET = '[Media] Reset';

export class SetScrollPosition implements Action {
  readonly type = SET_SCROLL_POSITION;

  constructor(public payload: any) {}
}

export class ResetMedia implements Action {
  readonly type = RESET_MEDIA;

  constructor() {}
}

export class Reset implements Action {
  readonly type = RESET;

  constructor() {}
}

export class FindMedia implements Action {
  readonly type = FIND_MEDIA;

  constructor(public payload: any, public refresh?: any, public filter?: any) {}
}

export class FindMediaSuccess implements Action {
  readonly type = FIND_MEDIA_SUCCESS;

  constructor(public payload: any) {}
}

export class FindMediaFailure implements Action {
  readonly type = FIND_MEDIA_FAILURE;

  constructor(public payload?: any) {}
}

export class FindVault implements Action {
  readonly type = FIND_VAULT;

  constructor(public payload: any) {}
}

export class FindVaultSuccess implements Action {
  readonly type = FIND_VAULT_SUCCESS;

  constructor(public payload: any) {}
}

export class FindVaultFailure implements Action {
  readonly type = FIND_VAULT_FAILURE;

  constructor(public payload?: any) {}
}

export class GetMedia implements Action {
  readonly type = GET_MEDIA;

  constructor(public payload: any) {}
}

export class GetMediaSuccess implements Action {
  readonly type = GET_MEDIA_SUCCESS;

  constructor(public payload: any, public viewStyle: string) { }
}

export class GetMediaFailure implements Action {
  readonly type = GET_MEDIA_FAILURE;

  constructor(public payload?: any) {}
}

export class GetAnalysis implements Action {
  readonly type = GET_ANALYSIS;

  constructor(public payload: any) {}
}

export class GetAnalysisSuccess implements Action {
  readonly type = GET_ANALYSIS_SUCCESS;

  constructor(public payload: any) {}
}

export class GetAnalysisFailure implements Action {
  readonly type = GET_ANALYSIS_FAILURE;

  constructor(public payload?: any) {}
}


export class GetVault implements Action {
  readonly type = GET_VAULT;

  constructor() {}
}

export class GetVaultSuccess implements Action {
  readonly type = GET_VAULT_SUCCESS;

  constructor(public payload: any) {}
}

export class GetVaultFailure implements Action {
  readonly type = GET_VAULT_FAILURE;

  constructor(public payload?: any) {}
}

// --------------------------------------
// Get all days
//

export class GetDays implements Action {
  readonly type = GET_DAYS;

  constructor(public payload?: any) {}
}

export class GetDaysSuccess implements Action {
  readonly type = GET_DAYS_SUCCESS;

  constructor(public payload: any) {}
}

export class GetDaysFailure implements Action {
  readonly type = GET_DAYS_FAILURE;

  constructor(public payload?: any) {}
}

// --------------------------------------
// Get all hours
//

export class GetHours implements Action {
  readonly type = GET_HOURS;

  constructor(public payload?: any) {}
}

export class GetHoursSuccess implements Action {
  readonly type = GET_HOURS_SUCCESS;

  constructor(public payload: any) {}
}

export class GetHoursFailure implements Action {
  readonly type = GET_HOURS_FAILURE;

  constructor(public payload?: any) {}
}

// --------------------------------------
// Get all hours for filter
//

export class GetHoursForFilter implements Action {
  readonly type = GET_HOURS_FOR_FILTER;

  constructor(public payload?: any,  public filter?: any) {}
}

export class GetHoursForFilterSuccess implements Action {
  readonly type = GET_HOURS_FOR_FILTER_SUCCESS;

  constructor(public payload: any) {}
}

export class GetHoursForFilterFailure implements Action {
  readonly type = GET_HOURS_FOR_FILTER_FAILURE;

  constructor(public payload?: any) {}
}

// --------------------------------------
// Star seuence
//

export class StarSequence implements Action {
  readonly type = STAR_SEQUENCE;

  constructor(public payload: any) {}
}

export class StarSequenceSuccess implements Action {
  readonly type = STAR_SEQUENCE_SUCCESS;

  constructor(public payload: any) {}
}

export class StarSequenceFailure implements Action {
  readonly type = STAR_SEQUENCE_FAILURE;

  constructor(public payload: any) {}
}

// --------------------------------------
// Delete sequence
//

export class DeleteSequence implements Action {
  readonly type = DELETE_SEQUENCE;

  constructor(public payload: any) {}
}

export class DeleteSequenceSuccess implements Action {
  readonly type = DELETE_SEQUENCE_SUCCESS;

  constructor(public payload: any) {}
}

export class DeleteSequenceFailure implements Action {
  readonly type = DELETE_SEQUENCE_FAILURE;

  constructor(public payload: any) {}
}

// --------------------------------------
// Add label
//

export class AddLabel implements Action {
  readonly type = ADD_LABEL;

  constructor(public payload: any) {}
}

export class AddLabelSuccess implements Action {
  readonly type = ADD_LABEL_SUCCESS;

  constructor(public payload: any) {}
}

export class AddLabelFailure implements Action {
  readonly type = ADD_LABEL_FAILURE;

  constructor(public payload: any) {}
}

// --------------------------------------
// Remove label
//

export class RemoveLabel implements Action {
  readonly type = REMOVE_LABEL;

  constructor(public payload: any) {}
}

export class RemoveLabelSuccess implements Action {
  readonly type = REMOVE_LABEL_SUCCESS;

  constructor(public payload: any) {}
}

export class RemoveLabelFailure implements Action {
  readonly type = REMOVE_LABEL_FAILURE;

  constructor(public payload: any) {}
}

// --------------------------------------
// Update description
//

export class UpdateDescription implements Action {
  readonly type = UPDATE_DESCRIPTION;

  constructor(public payload: any) {}
}

export class UpdateDescriptionSuccess implements Action {
  readonly type = UPDATE_DESCRIPTION_SUCCESS;

  constructor(public payload: any) {}
}

export class UpdateDescriptionFailure implements Action {
  readonly type = UPDATE_DESCRIPTION_FAILURE;

  constructor(public payload: any) {}
}

// ---------------------------
// Adding media to the vault
//

export class AddToVault implements Action {
  readonly type = ADD_TO_VAULT;

  constructor(public payload: any) {}
}

export class AddToVaultSuccess implements Action {
  readonly type = ADD_TO_VAULT_SUCCESS;

  constructor(public payload: any) {}
}

export class AddToVaultFailure implements Action {
  readonly type = ADD_TO_VAULT_FAILURE;

  constructor(public payload: any) {}
}

// ---------------------------
// Removing media from the vault
//

export class RemoveFromVault implements Action {
  readonly type = REMOVE_FROM_VAULT;

  constructor(public payload: any) {}
}

export class RemoveFromVaultSuccess implements Action {
  readonly type = REMOVE_FROM_VAULT_SUCCESS;

  constructor(public payload: any) {}
}

export class RemoveFromVaultFailure implements Action {
  readonly type = REMOVE_FROM_VAULT_FAILURE;

  constructor(public payload: any) {}
}


// ---------------------------------
// Share media with external people
//

export class ShareMedia implements Action {
  readonly type = SHARE_MEDIA;

  constructor(public payload: any) {}
}

export class ShareMediaSuccess implements Action {
  readonly type = SHARE_MEDIA_SUCCESS;

  constructor(public payload: any) {}
}

export class ShareMediaFailure implements Action {
  readonly type = SHARE_MEDIA_FAILURE;

  constructor(public payload: any) {}
}


// --------------------
// Get and set filter
//

export class GetFilter implements Action {
  readonly type = GET_FILTER;

  constructor(public payload?: any) {}
}

export class SetFilter implements Action {
  readonly type = SET_FILTER;

  constructor(public payload?: any) {}
}

// -----------------------------
// Task created/deleted for item.
//

export class TaskCreatedSuccess implements Action {
  readonly type = TASK_CREATED_SUCCESS;

  constructor(public payload: any, public sequenceId:string, public mediaId:number) {}
}

export class TaskDeletedSuccess implements Action {
  readonly type = TASK_DELETED_SUCCESS;

  constructor(public payload: any, public sequenceId:string, public mediaId:number) {}
}

// -----------------------------
// Export media
//

export class GetExportMedia implements Action {
  readonly type = GET_EXPORT_MEDIA;

  constructor(public filter?: any) {}
}

export class GetExportMediaSuccess implements Action {
  readonly type = GET_EXPORT_MEDIA_SUCCESS;

  constructor(public payload: any) {}
}

export class GetExportMediaFailure implements Action {
  readonly type = GET_EXPORT_MEDIA_FAILURE;

  constructor(public payload: any) {}
}


export type Actions =
  | SetScrollPosition
  | ResetMedia
  | GetMedia
  | GetMediaSuccess
  | GetMediaFailure
  | GetAnalysis
  | GetAnalysisSuccess
  | GetAnalysisFailure
  | GetVault
  | GetVaultSuccess
  | GetVaultFailure
  | FindMedia
  | FindMediaSuccess
  | FindMediaFailure
  | FindVault
  | FindVaultSuccess
  | FindVaultFailure
  | GetDays
  | GetDaysSuccess
  | GetDaysFailure
  | GetHours
  | GetHoursSuccess
  | GetHoursFailure
  | GetHoursForFilter
  | GetHoursForFilterSuccess
  | GetHoursForFilterFailure
  | StarSequence
  | StarSequenceSuccess
  | StarSequenceFailure
  | DeleteSequence
  | DeleteSequenceSuccess
  | DeleteSequenceFailure
  | AddLabel
  | AddLabelSuccess
  | AddLabelFailure
  | RemoveLabel
  | RemoveLabelSuccess
  | RemoveLabelFailure
  | AddToVault
  | AddToVaultSuccess
  | AddToVaultFailure
  | RemoveFromVault
  | RemoveFromVaultSuccess
  | RemoveFromVaultFailure
  | ShareMedia
  | ShareMediaSuccess
  | ShareMediaFailure
  | GetFilter
  | SetFilter
  | TaskCreatedSuccess
  | TaskDeletedSuccess
  | GetExportMedia
  | GetExportMediaSuccess
  | GetExportMediaFailure
  | Reset;
