import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Label } from 'src/app/shared/models/interfaces';

@Component({
  selector: 'LabelForm',
  templateUrl: './label-form.component.html',
  styleUrls: ['../label-row/label-row.component.scss']
})
export class LabelFormComponent implements OnInit {
  @Output() addLabel: EventEmitter<Label> = new EventEmitter<Label>();

  addForm: FormGroup;
  error: string = '';

  constructor(private fb: FormBuilder) {
    this.addForm = this.fb.group({
      color: ['#84569f', { validators: [Validators.required], updateOn: 'change' }],
      name: ['', Validators.required],
      description: ['', Validators.required]
    }, { updateOn: 'submit' });
  }

  ngOnInit() {
  }

  onAddSubmit(): void {
    if (this.addForm.valid) {
      const newLabel: Label = {
        ...this.addForm.value
      };
      this.addLabel.emit(newLabel);
      this.addForm.reset({ color: '#84569f', name: '', description: '' });
      this.error = '';
    } else {
      this.error = 'Please fill out all required fields.';
    }
  }

}
