import { Action } from '@ngrx/store';

export const RESET = '[Videowalls] Reset';
export const REQUEST_VIDEOWALLS = '[Videowalls] Requesting videowalls';
export const REQUEST_VIDEOWALLS_SUCCESS = '[Videowalls] Requesting videowalls Success';
export const REQUEST_VIDEOWALLS_FAILURE = '[Videowalls] Requesting videowalls Failure';
export const FIND_VIDEOWALL = '[Videowalls] Find videowall';
export const FIND_VIDEOWALL_SUCCESS = '[Videowalls] Find videowall Success';
export const FIND_VIDEOWALL_FAILURE = '[Videowalls] Find videowall Failure';
export const ADD_VIDEOWALL = '[Videowalls] Add videowall';
export const ADD_VIDEOWALL_SUCCESS = '[Videowalls] Add videowall Success';
export const ADD_VIDEOWALL_FAILURE = '[Videowalls] Add videowall Failure';
export const EDIT_VIDEOWALL = '[Videowalls] Edit videowall';
export const EDIT_VIDEOWALL_SUCCESS = '[Videowalls] Edit videowall Success';
export const EDIT_VIDEOWALL_FAILURE = '[Videowalls] Edit videowall Failure';
export const UPDATE_VIDEOWALL = '[Videowalls] Update videowall';
export const UPDATE_VIDEOWALL_SUCCESS = '[Videowalls] Update videowall Success';
export const UPDATE_VIDEOWALL_FAILURE = '[Videowalls] Update videowall Failure';
export const DELETE_VIDEOWALL = '[Videowalls] Delete videowall';
export const DELETE_VIDEOWALL_SUCCESS = '[Videowalls] Delete videowall Success';
export const DELETE_VIDEOWALL_FAILURE = '[Videowalls] Delete videowall Failure';

export class Reset implements Action {
  readonly type = RESET;

  constructor() {}
}

export class RequestVideowalls implements Action {
  readonly type = REQUEST_VIDEOWALLS;

  constructor() {}
}

export class RequestVideowallsSuccess implements Action {
  readonly type = REQUEST_VIDEOWALLS_SUCCESS;

  constructor(public payload: any) {}
}

export class RequestVideowallsFailure implements Action {
  readonly type = REQUEST_VIDEOWALLS_FAILURE;

  constructor(public payload?: any) {}
}

export class FindVideowall implements Action {
  readonly type = FIND_VIDEOWALL;

  constructor(public payload: any) {}
}

export class FindVideowallSuccess implements Action {
  readonly type = FIND_VIDEOWALL_SUCCESS;

  constructor(public payload: any) {}
}

export class FindVideowallFailure implements Action {
  readonly type = FIND_VIDEOWALL_FAILURE;

  constructor(public payload?: any) {}
}

export class AddVideowall implements Action {
  readonly type = ADD_VIDEOWALL;

  constructor(public payload: any) {}
}

export class AddVideowallSuccess implements Action {
  readonly type = ADD_VIDEOWALL_SUCCESS;

  constructor(public payload: any) {}
}

export class AddVideowallFailure implements Action {
  readonly type = ADD_VIDEOWALL_FAILURE;

  constructor(public payload?: any) {}
}

export class EditVideowall implements Action {
  readonly type = EDIT_VIDEOWALL;

  constructor(public id: any, public payload: any) {}
}

export class EditVideowallSuccess implements Action {
  readonly type = EDIT_VIDEOWALL_SUCCESS;

  constructor(public payload: any) {}
}

export class EditVideowallFailure implements Action {
  readonly type = EDIT_VIDEOWALL_FAILURE;

  constructor(public payload?: any) {}
}

export class UpdateVideowall implements Action {
  readonly type = UPDATE_VIDEOWALL;

  constructor(public id: any, public payload: any) {}
}

export class UpdateVideowallSuccess implements Action {
  readonly type = UPDATE_VIDEOWALL_SUCCESS;

  constructor(public payload: any) {}
}

export class UpdateVideowallFailure implements Action {
  readonly type = UPDATE_VIDEOWALL_FAILURE;

  constructor(public payload?: any) {}
}

export class DeleteVideowall implements Action {
  readonly type = DELETE_VIDEOWALL;

  constructor(public id: any) {}
}

export class DeleteVideowallSuccess implements Action {
  readonly type = DELETE_VIDEOWALL_SUCCESS;

  constructor(public payload: any) {}
}

export class DeleteVideowallFailure implements Action {
  readonly type = DELETE_VIDEOWALL_FAILURE;

  constructor(public payload?: any) {}
}

export type Actions =
  | Reset
  | RequestVideowalls
  | RequestVideowallsSuccess
  | RequestVideowallsFailure
  | FindVideowall
  | FindVideowallSuccess
  | FindVideowallFailure
  | AddVideowall
  | AddVideowallSuccess
  | AddVideowallFailure
  | EditVideowall
  | EditVideowallSuccess
  | EditVideowallFailure
  | UpdateVideowall
  | UpdateVideowallSuccess
  | UpdateVideowallFailure
  | DeleteVideowall
  | DeleteVideowallSuccess
  | DeleteVideowallFailure;
