import * as fromRoot from '../../../app.reducer';
import { LabelsState } from './labels/labels';
import { SettingsState, reducer as settingsReducer} from './settings';
import { createSelector, createFeatureSelector, ActionReducerMap } from '@ngrx/store';

export * from './effects';

export interface State extends fromRoot.State {
    settings: SettingsState;
}

export const Reducers = settingsReducer;

export const selectSettingsState = createFeatureSelector<SettingsState>('settings');

export const selectLabelsState = createSelector(
    selectSettingsState,
    (state: SettingsState) => state.labels
)
export const selectLabels = createSelector(
    selectLabelsState,
    (labelsState) => labelsState
)
export const selectAllLabels = createSelector(
    selectSettingsState,
    (state: SettingsState) => state.labels.values
)
export const isLabelsLoading = createSelector(
    selectSettingsState,
    (state: SettingsState) => state.labels.loading
)
export const selectLabelsLoaded = createSelector(
    selectSettingsState,
    (state: SettingsState) => state.labels.loaded
)
export const selectLabelsError = createSelector(
    selectSettingsState,
    (state: SettingsState) => state.labels.error
)

