import { Actions, Effect, ofType } from "@ngrx/effects";
import { SettingsService } from "../../settings.service";
import { Injectable } from "@angular/core";
import { of as observableOf, Observable } from "rxjs";
import * as Action from "./actions";
import { catchError, map, switchMap } from "rxjs/operators";

@Injectable()
export class Effects {

  @Effect()
  loadLabels: Observable<Action.LoadLabelsFailure | Action.LoadLabelsSuccess> = this.actions$.pipe(
    ofType(Action.LOAD_LABELS),
    switchMap((action: Action.LoadLabels) => {
      return this.settingsService.getLabels().pipe(map((labels) => {
        return new Action.LoadLabelsSuccess(labels);
      }),
      catchError(e => {
        return observableOf(new Action.LoadLabelsFailure(e));
      }),);
    })
  );

  @Effect()
  addLabel: Observable<Action.AddLabelFailure | Action.AddLabelSuccess> = this.actions$.pipe(
    ofType(Action.ADD_LABEL),
    switchMap((action: Action.AddLabel) => {
      return this.settingsService.addLabel(action.payload).pipe(map((label) => {
        return new Action.AddLabelSuccess(label);
      }),
      catchError(e => {
        return observableOf(new Action.AddLabelFailure(e));
      }),);
    })
  );

  @Effect()
  updateLabel: Observable<Action.UpdateLabelFailure | Action.UpdateLabelSuccess> = this.actions$.pipe(
    ofType(Action.UPDATE_LABEL),
    switchMap((action: Action.UpdateLabel) => {
      return this.settingsService.updateLabel(action.payload).pipe(map((label) => {
        return new Action.UpdateLabelSuccess(label);
      }),
      catchError(e => {
        return observableOf(new Action.UpdateLabelFailure(e));
      }),);
    })
  );

  @Effect()
  DeleteLabel: Observable<Action.DeleteLabelFailure | Action.DeleteLabelSuccess> = this.actions$.pipe(
    ofType(Action.DELETE_LABEL),
    switchMap((action: Action.DeleteLabel) => {
      return this.settingsService.deleteLabel(action.id).pipe(map(() => {
        return new Action.DeleteLabelSuccess(action.id);
      }),
      catchError(e => {
        return observableOf(new Action.DeleteLabelFailure(e));
      }),);
    })
  );

  constructor(private actions$: Actions, private settingsService: SettingsService) {}
}