import { ModuleWithProviders }  from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { PageNotFoundComponent } from './error/pagenotfound.component';
import { ClickOutsideDirective } from './shared/clickOutside.directive';


export class AppRoutes {
  public static getDeclarations() : any {
    return [
      AppComponent,
      PageNotFoundComponent,
    ];
  }

  public static getAppRoutes() : ModuleWithProviders {
    return RouterModule.forRoot([
      { path: '**', component: PageNotFoundComponent }
    ]);
  }

  public static getBootstrapComponent() : any {
    return AppComponent;
  }
};
