import {of as observableOf,  Observable } from 'rxjs';
import {catchError, switchMap, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as Action from '../store/actions';
import { VideowallService } from '../videowall.service';

@Injectable()
export class Effects {

    /* Retrieve all videowalls */

    @Effect()
    requestVideowalls: Observable<Action.RequestVideowallsFailure | Action.RequestVideowallsSuccess> = this.actions$.pipe(
        ofType(Action.REQUEST_VIDEOWALLS),
        switchMap((action: Action.RequestVideowalls) => {
          return this.videowallService.getVideowalls().pipe(map((videowalls) => {
            return new Action.RequestVideowallsSuccess(videowalls);
          }),
          catchError(e => {
            return observableOf(new Action.RequestVideowallsFailure(e));
          }),);
        }));

    /* Add videowall */

    @Effect()
    addVideowall: Observable<Action.AddVideowallFailure | Action.AddVideowallSuccess> = this.actions$.pipe(
        ofType(Action.ADD_VIDEOWALL),
        switchMap((action: Action.AddVideowall) => {
          return this.videowallService.addVideowall(action.payload).pipe(map((videowalls) => {
            return new Action.AddVideowallSuccess(videowalls);
          }),
          catchError(e => {
            return observableOf(new Action.AddVideowallFailure(e.error.message));
          }),);
        }));

    /* Edit videowall */

    @Effect()
    editVideowall: Observable<Action.EditVideowallFailure | Action.EditVideowallSuccess> = this.actions$.pipe(
        ofType(Action.EDIT_VIDEOWALL),
        switchMap((action: Action.EditVideowall) => {
          return this.videowallService.editVideowall(action.id, action.payload).pipe(map((videowalls) => {
            return new Action.EditVideowallSuccess(videowalls);
          }),
          catchError(e => {
            return observableOf(new Action.EditVideowallFailure(e.error.message));
          }),);
        }));

    /* Update videowall */
    
    @Effect()
    updateVideowall: Observable<Action.UpdateVideowallFailure | Action.UpdateVideowallSuccess> = this.actions$.pipe(
        ofType(Action.UPDATE_VIDEOWALL),
        switchMap((action: Action.UpdateVideowall) => {
          return this.videowallService.updateVideowall(action.id, action.payload).pipe(map((videowalls) => {
            return new Action.UpdateVideowallSuccess(videowalls);
          }),
          catchError(e => {
            return observableOf(new Action.UpdateVideowallFailure(e.error.message));
          }),);
        }));

    /* Delete videowall */

    @Effect()
    deleteVideowall: Observable<Action.DeleteVideowallFailure | Action.DeleteVideowallSuccess> = this.actions$.pipe(
        ofType(Action.DELETE_VIDEOWALL),
        switchMap((action: Action.DeleteVideowall) => {
          return this.videowallService.deleteVideowall(action.id).pipe(map((videowalls) => {
            return new Action.DeleteVideowallSuccess(videowalls);
          }),
          catchError(e => {
            return observableOf(new Action.DeleteVideowallFailure(e));
          }),);
        }));

    constructor(private videowallService: VideowallService,
                private actions$: Actions) {}
}
