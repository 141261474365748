import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'ControlsPopup',
  templateUrl: './controls-popup.component.html',
  styleUrls: ['./controls-popup.component.scss']
})
export class ControlsPopupComponent {
  @Input() isOpen: boolean = false;
  
  @Output() edit = new EventEmitter<void>();
  @Output() delete = new EventEmitter<void>();

  closePopover(): void {
    this.isOpen = false;
  }

  onEdit(): void {
    this.edit.emit();
    this.closePopover();
  }

  onDelete(): void {
    this.delete.emit();
    this.closePopover();
  }
}