import { Action } from "@ngrx/store";
import { Label } from "src/app/shared/models/interfaces";

export const LOAD_LABELS = '[Labels] Load Labels';
export const LOAD_LABELS_SUCCESS = '[Labels] Load Labels Success';
export const LOAD_LABELS_FAILURE = '[Labels] Load Labels Failure';

export const ADD_LABEL = '[Labels] Add Label';
export const ADD_LABEL_SUCCESS = '[Labels] Add Label Success';
export const ADD_LABEL_FAILURE = '[Labels] Add Label Failure';

export const UPDATE_LABEL = '[Labels] Update Label';
export const UPDATE_LABEL_SUCCESS = '[Labels] Update Label Success';
export const UPDATE_LABEL_FAILURE = '[Labels] Update Label Failure';

export const DELETE_LABEL = '[Labels] Delete Label';
export const DELETE_LABEL_SUCCESS = '[Labels] Delete Label Success';
export const DELETE_LABEL_FAILURE = '[Labels] Delete Label Failure';

export class LoadLabels implements Action {
    readonly type = LOAD_LABELS;
}

export class LoadLabelsSuccess implements Action {
readonly type = LOAD_LABELS_SUCCESS;
constructor(public payload: Label[]) {}
}

export class LoadLabelsFailure implements Action {
readonly type = LOAD_LABELS_FAILURE;
constructor(public payload: string) {}
}

export class AddLabel implements Action {
readonly type = ADD_LABEL;
constructor(public payload: Label) {}
}

export class AddLabelSuccess implements Action {
readonly type = ADD_LABEL_SUCCESS;
constructor(public payload: Label) {}
}

export class AddLabelFailure implements Action {
readonly type = ADD_LABEL_FAILURE;
constructor(public payload: string) {}
}

export class UpdateLabel implements Action {
readonly type = UPDATE_LABEL;
constructor(public payload: Label) {}
}

export class UpdateLabelSuccess implements Action {
readonly type = UPDATE_LABEL_SUCCESS;
constructor(public payload: Label) {}
}

export class UpdateLabelFailure implements Action {
readonly type = UPDATE_LABEL_FAILURE;
constructor(public payload: string) {}
}

export class DeleteLabel implements Action {
    readonly type = DELETE_LABEL;
    constructor(public id: string) {}
}

export class DeleteLabelSuccess implements Action {
    readonly type = DELETE_LABEL_SUCCESS;
    constructor(public payload: string) {}
}

export class DeleteLabelFailure implements Action {
    readonly type = DELETE_LABEL_FAILURE;
    constructor(public payload: string) {}
}

export type LabelsActions =
| LoadLabels
| LoadLabelsSuccess
| LoadLabelsFailure
| AddLabel
| AddLabelSuccess
| AddLabelFailure
| UpdateLabel
| UpdateLabelSuccess
| UpdateLabelFailure
| DeleteLabel
| DeleteLabelSuccess
| DeleteLabelFailure;