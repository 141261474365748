import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Label } from '../../models/interfaces';

@Component({
  selector: 'LabelPicker',
  templateUrl: './label-picker.component.html',
  styleUrls: ['./label-picker.component.scss']
})
export class LabelPickerComponent implements OnInit {

  @Input() labels: Label[];
  @Input() selectedLabels: Label[];

  @Input() withSearch: boolean = false;
  @Input() withControls: boolean = false;

  @Output() onSave: EventEmitter<string[]> = new EventEmitter<string[]>();
  @Output() onChange: EventEmitter<string[]> = new EventEmitter<string[]>();

  public selectedItems: string[] = [];

  constructor() { }

  ngOnInit() {
    if(this.selectedLabels) {
      this.selectedItems = this.selectedLabels.map(label => label.id);
    }
  }

  changeSelect(label: Label, event: Event) {
    const checked = (event.target as HTMLInputElement).checked;
    if (checked) {
      this.selectedItems.push(label.id);
    } else {
      this.selectedItems = this.selectedItems.filter(id => id !== label.id);
    }
    this.onChange.emit(this.selectedItems);
  }

  isSelected(labelId: string): boolean {
    return this.selectedItems.includes(labelId);
  }

  onSaveLabels() {
    this.onSave.emit(this.selectedItems);
  }
}
