import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ApiService } from 'src/app/shared/api.service';
import { DeleteLabel } from './store/actions';
import { Label } from 'src/app/shared/models/interfaces';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor(private http: HttpClient,
    private apiService: ApiService) { }

  public getLabels() : Observable<any> {
    return this.http.get(this.apiService.getSettingsLabels()).pipe(take(1),map(value => {
      return value;
    }),);
  }

  public addLabel(label: Label): Observable<any> {
    return this.http.post(this.apiService.addSettingsLabel(), label).pipe(take(1),map(value => {
      return value;
    }),);
  }

  public updateLabel(label: Label): Observable<any> {
    return this.http.patch(this.apiService.updateSettingsLabel(label.id), label).pipe(take(1),map(value => {
      return value
    }
    ),);
  }

  public deleteLabel(id: string): Observable<any> {
    return this.http.delete(this.apiService.deleteSettingsLabel(id)).pipe(take(1),map(value => {
      return value;
    }),);
  }
}
