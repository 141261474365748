import * as labels from './actions';
import { Label } from '../../../../shared/models/interfaces';

export interface LabelsState {
  values: Label[];
  loading: boolean;
  loaded: boolean;
  error: any;
}

export const initialLabelState: LabelsState = {
  values: [],
  loading: false,
  loaded: false,
  error: null,
};

export function reducer(state = initialLabelState, action: labels.LabelsActions): LabelsState {
  switch (action.type) {
    case labels.LOAD_LABELS:
      return { 
        ...state, 
        loading: true 
    };

    case labels.LOAD_LABELS_SUCCESS:
      return { 
        ...state, 
        loading: false, 
        loaded: true, 
        values: action.payload 
    };

    case labels.LOAD_LABELS_FAILURE:
      return { 
        ...state, 
        loading: false, 
        loaded: false, 
        error: action.payload 
    };

    case labels.ADD_LABEL:
      return { 
        ...state, 
        loading: true 
    };

    case labels.ADD_LABEL_SUCCESS:
      return { 
        ...state, 
        loading: false, 
        loaded: true, 
        values: [...state.values, action.payload] 
    };

    case labels.ADD_LABEL_FAILURE:
      return { 
        ...state, 
        loading: false, 
        loaded: false, 
        error: action.payload 
    };
    
    case labels.DELETE_LABEL:
      return {
        ...state,
        loading: true
      };

    case labels.DELETE_LABEL_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        values: state.values.filter(label => label.id !== action.payload)
      };

    case labels.DELETE_LABEL_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload
      };

     case labels.UPDATE_LABEL:
      return {
        ...state,
        loading: true
      };

    case labels.UPDATE_LABEL_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        values: state.values.map(label => label.id === action.payload.id ? action.payload : label)
      };

    case labels.UPDATE_LABEL_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload
      };
  }
}