// label-row.component.ts
import { Component, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { Label } from '../../../models/interfaces';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'LabelRow',
  templateUrl: './label-row.component.html',
  styleUrls: ['./label-row.component.scss']
})
export class LabelRowComponent {
  @Input() label!: Label;
  
  @Output() editLabel = new EventEmitter<Label>();
  @Output() deleteLabel = new EventEmitter<Label>();

  isEditing: boolean = false;
  showControls: boolean = false;

  editForm: FormGroup;

  deleteModal: boolean = false;

  constructor(private fb: FormBuilder) {
    this.editForm = this.fb.group({
      name: ['', Validators.required],
      description: ['', Validators.required],
      color: ['#000000', Validators.required]
    });
  }
  toggleControls(): void {
    event.stopPropagation();
    this.showControls = !this.showControls;
  }
  closeControls(): void {
    this.showControls = false;
  }

  startEdit(): void {
    this.isEditing = true;
    this.editForm.setValue({
      name: this.label.name,
      description: this.label.description,
      color: this.label.color
    });
  }

  cancelEdit(): void {
    this.isEditing = false;
    this.showControls = false;
    this.editForm.reset({ color: '#000000', name: '', description: '' });
  }

  saveEdit(): void {
    if (this.editForm.valid) {
      const updatedLabel: Label = {
        id: this.label.id,
        ...this.editForm.value
      };
      this.editLabel.emit(updatedLabel);
      this.isEditing = false;
      this.showControls = false;
    }
  }

  deleteCurrentLabel(): void {
    this.deleteLabel.emit(this.label);
    this.showControls = false;
  }

  openDeleteModal(): void {
    this.deleteModal = true;
  }

  closeDeleteModal(): void {
    this.deleteModal = false;
  }
}