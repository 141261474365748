// src/app/home/settings/store/settings.reducer.ts

import { Action } from '@ngrx/store';
import * as settings from './actions';
import * as labels from './labels/actions';
import * as fromLabels from './labels/labels';
import { Settings } from '../../../shared/models/interfaces';


export interface SettingsState {
  values: Settings | null;
  loading: boolean;
  loaded: boolean;
  error: any;

  // Integrated LabelsState
  labels: fromLabels.LabelsState;
}

export const initialState: SettingsState = {
  // Initialize existing settings properties
  values: null,
  loading: false,
  loaded: false,
  error: null,

  // Initialize LabelsState
  labels: fromLabels.initialLabelState
};

export function reducer(state = initialState, action: settings.SettingsActions | labels.LabelsActions): SettingsState {

  if (action.type.startsWith('[Labels]')) {
    return {
      ...state,
      labels: fromLabels.reducer(state.labels, <labels.LabelsActions>action)
    };
  }

  switch (action.type) {
    case settings.LOAD_SETTINGS:
      return { ...state, loading: true };

    case settings.LOAD_SETTINGS_SUCCESS:
      return { ...state, loading: false, loaded: true, values: action.payload };

    case settings.LOAD_SETTINGS_FAILURE:
      return { ...state, loading: false, loaded: false, error: action.payload };

    default:
      return state;
  }
}