import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as Action from './actions';
import { switchMap, map, catchError } from 'rxjs/operators';
import {of as observableOf,  Observable } from 'rxjs';
import { SettingsService } from '../settings.service';
import * as labelEffeccts from './labels/effects'; 

@Injectable()
export class Effects {

  // Placeholder for settings effects

  constructor(private actions$: Actions, private settingsService: SettingsService) {}
}