
import {map, take} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { ApiService } from '../../shared/api.service';

@Injectable()
export class VideowallService {

  constructor(private http: HttpClient,
              private apiService: ApiService) {}

  public getVideowalls() : Observable<any> {
    return this.http.get(this.apiService.getVideowalls()).pipe(take(1),map((value: any) => {
      const { data } = value;
      return data;
    }),);
  }

  public getVideowall(id) : Observable<any> {
    return this.http.get(this.apiService.getVideowall(id)).pipe(take(1),map((value: any) => {
      const { data } = value;
      return data;
    }),);
  }

  public addVideowall(videowall) : Observable<any> {
    return this.http.post(this.apiService.addVideowall(), videowall).pipe(take(1),map((value: any) => {
      const { data } = value;
      return data;
    }),);
  }

  public editVideowall(id, videowall) : Observable<any> {
    return this.http.put(this.apiService.editVideowall(id), videowall).pipe(take(1),map((value: any) => {
      const { data } = value;
      return data;
    }),);
  }

  public updateVideowall(id, updates) : Observable<any> {
    return this.http.patch(this.apiService.updateVideowall(id), updates).pipe(take(1),map((value: any) => {
      const { data } = value;
      return data;
    }),);
  }

  public deleteVideowall(id) : Observable<any> {
    return this.http.delete(this.apiService.deleteVideowall(id)).pipe(take(1),map((value: any) => {
      const { data } = value;
      return data;
    }),);
  }
}
