import { Action } from '@ngrx/store';
import { Label } from '../../../shared/models/interfaces';
import {LabelsActions} from './labels/actions';

// export actions for labels to centralize in settingsactions
export * from './labels/actions';

export const LOAD_SETTINGS = '[Settings] Load Settings';
export const LOAD_SETTINGS_SUCCESS = '[Settings] Load Settings Success';
export const LOAD_SETTINGS_FAILURE = '[Settings] Load Settings Failure';

export class LoadSettings implements Action {
    readonly type = LOAD_SETTINGS;
}

export class LoadSettingsSuccess implements Action {
    readonly type = LOAD_SETTINGS_SUCCESS;
    constructor(public payload: any) {}
}

export class LoadSettingsFailure implements Action {
    readonly type = LOAD_SETTINGS_FAILURE;
    constructor(public payload: string) {}
}

export type SettingsActions =
| LoadSettings
| LoadSettingsSuccess
| LoadSettingsFailure
| LabelsActions;

